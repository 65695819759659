body {
  font-family: "Poppins" !important;
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100 !important;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200 !important;
  font-style: normal !important;
}

.poppins-light {
  font-family: "Poppins", sans-serif !important;
  font-weight: 300 !important;
  font-style: normal !important;
}

.poppins-regular {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

.poppins-medium {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  font-style: normal !important;
}

.poppins-bold {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-style: normal !important;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif !important;
  font-weight: 800 !important;
  font-style: normal !important;
}

.poppins-black {
  font-family: "Poppins", sans-serif !important;
  font-weight: 900 !important;
  font-style: normal !important;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif !important;
  font-weight: 100 !important;
  font-style: italic !important;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif !important;
  font-weight: 200 !important;
  font-style: italic !important;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif !important;
  font-weight: 300 !important;
  font-style: italic !important;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  font-style: italic !important;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  font-style: italic !important;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  font-style: italic !important;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-style: italic !important;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif !important;
  font-weight: 800 !important;
  font-style: italic !important;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif !important;
  font-weight: 900 !important;
  font-style: italic !important;
}
